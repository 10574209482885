@import url('https://fonts.googleapis.com/css2?family=Abel&family=Anton&family=Bebas+Neue&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Lilita+One&family=Oswald:wght@200..700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Rubik+Dirt&family=Viga&display=swap');
/* font-family: "DM Sans", sans-serif; */


.brands-carousel-container {
    width: 100%;
    margin: 0 auto;
    border: 1px solid rgb(19, 19, 19);
    background-color: rgba(34, 34, 34, 0.397);
  }
  
  .brand-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(165, 165, 165);
    font-size: 1.2rem;
    font-weight: lighter;
    text-align: center;
    background-image: linear-gradient(to right, #ffffff, #cacaca,#1d1d1d);
    background-clip: text; /* Standard property */
    -webkit-background-clip: text; /* WebKit-based browsers */
    color: transparent; /* Make the text color transparent so the gradient shows */
    h3{
        margin: 0;
        font-family: "DM Sans", sans-serif;
    }
  }
  
  .slick-dots li button:before {
    color: white;
  }
  
  .slick-prev:before,
  .slick-next:before {
    color: white;
  }
  